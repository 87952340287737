<template>
  <section id="Works">
    <div id="works-page-list" v-if="$route.name == 'Works'">
      <page-title title="My Works"/>
      <div class="works-container">
        <router-link v-for="project in projects" :key="project.id" :to="'works/' + project.routePath">
          <WorkCard 
            class="elm"
            :title="project.title" 
            :cover="project.cover"
          />
        </router-link>
      </div>
    </div>

    <!-- view - serve a visualizzare le nested routes -->
    <router-view v-if="$route.name != 'Works'"></router-view>

  </section>
</template>

<script>
import WorkCard from '@/components/small/WorkCard.vue'
import PageTitle from '../components/small/PageTitle.vue'

export default {
  name: 'Works',
  components:{
    WorkCard,
    PageTitle
  },
  metaInfo: {
    title: 'Works',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Ciao! Sono Aris Goi un designer e developer, questa è la sezione cuore del mio portfolio all\'interno troverai alcuni dei miei lavori preferiti!',
    }],
  },
  props:{
    // msg: String
  },
  data(){
    return{
      projects: []
    }
  },
  methods: {
    //
  },
  created() {
    // make a projects list get from router(file)
    this.$router.options.routes.forEach(route => {
      if(route.name == 'Works'){
        route.children.forEach(child => {
          this.projects.push({
              id: child.id,
              routeName: child.name,
              routePath: child.path,
              title: child.title,
              cover: child.coverName,
              date: child.date
          })
        })
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

#works-page-list{
  margin-bottom: 25px;
}

.works-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    max-width: 1200px;
    float: none;
    margin-left: auto;
    margin-right: auto;

    .elm{
      margin: 20px;
    }
}

</style>