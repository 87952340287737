<template>
    <section id="WorkCard">
        <div class="wk-box">
            <h2 class="wk-title">
                <span v-if="title.length<20">{{ title }}</span>
                <span v-else>{{ title.substring(0,20)+".." }}</span>
            </h2>
            <img :src="require(`@/assets/imgs/${cover}`)" alt="">
        </div>
    </section>
</template>

<script>
export default {
    name: 'WorkCard',
    props:{
        title: String,
        cover: String
    },
    data(){
        return{
            //
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
.wk-box, .wk-title{
    transition: 0.4s;
}

.wk-box{
    position: relative;
    width: 320px;
    height: 220px;
    @include img-fit-cover;
    border: $main-borders;
    box-shadow: $shadow;

    &:hover{
        border-color: $c-02;
    }

    h2.wk-title{
        position: absolute;
        right: -18px;
        bottom: 0;
        transform: translateY(50%);
        max-width: 100%;
        padding: 2px 10px;
        border: $main-borders;
        background-color: $c-03;
    }
}

</style>